import Vue from 'vue'
import Vuex from 'vuex'

import appModule from './modules/app'
import boardModule from './modules/board'
import categoryModule from './modules/category'
import groupConfigModule from './modules/groupConfig'
import newsPublishModule from './modules/newsPublish'
import taskArticle from './modules/taskArticle'
import taskArticlePublishStatus from './modules/taskArticlePublishStatus'
import taskArticleStatus from './modules/taskArticleStatus'
import taskAssetModule from './modules/taskAsset'
import taskAssignManagementModule from './modules/taskAssignManagement'
import taskAssignmentModule from './modules/taskAssignment'
import taskModule from './modules/task'
import topicManagementModule from './modules/topicManagement'
import widgetConfigModule from './modules/widgetConfig'

Vue.use(Vuex)

export function createStore() {
  return new Vuex.Store({
    modules: {
      appModule,
      boardModule,
      categoryModule,
      groupConfigModule,
      newsPublishModule,
      taskArticle,
      taskArticlePublishStatus,
      taskArticleStatus,
      taskAssetModule,
      taskAssignManagementModule,
      taskAssignmentModule,
      taskModule,
      topicManagementModule,
      widgetConfigModule,
    },
  })
}

const store = createStore()

export default store
