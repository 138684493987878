import vue from 'vue'

// interface
import { GroupConfigModel } from '@technology/collaborate-next-js/dist/types/models'
import { GroupConfigState } from '~/store/interfaces/state'

import * as types from './mutation-types'

/*
  Need to use export default({}) rather than export default {}
  ref links: https://github.com/TypeStrong/ts-loader/issues/588
*/
export default {
  [types.GROUP_CONFIG_SET_GROUP_CONFIG](
    state: GroupConfigState,
    payload: NormalizedPayload<GroupConfigModel, number>,
  ) {
    for (const entity in payload.entities) {
      if (payload.entities.hasOwnProperty(entity)) {
        const oldObj = state.entities[entity] || {}
        const newObj = { ...oldObj, ...payload.entities[entity] }
        vue.set(state.entities, entity, newObj)
      }
    }
    for (const id in payload.ids) {
      if (payload.ids.hasOwnProperty(id)) {
        const key = payload.ids[id]
        if (state.entityIds.indexOf(key) < 0) {
          state.entityIds.push(key)
        }
      }
    }
  },
}
