import { TaskArticlePublishStatusModel } from '@technology/collaborate-next-js/dist/types/models'
import vue from 'vue'

// interface
import { TaskArticlePublishStatusState } from '~/store/interfaces/state'

import * as types from './mutation-types'

export default {
  [types.TASK_ARTICLE_PUBLISH_STATUS_SET_TASK_ARTICLE_PUBLISH_STATUS](
    state: TaskArticlePublishStatusState,
    payload: NormalizedPayload<TaskArticlePublishStatusModel>,
  ) {
    for (const entity in payload.entities) {
      if (payload.entities.hasOwnProperty(entity)) {
        const oldObj = state.entities[entity] || {}
        const newObj = { ...oldObj, ...payload.entities[entity] }
        vue.set(state.entities, entity, newObj)
      }
    }
    for (const id in payload.ids) {
      if (payload.ids.hasOwnProperty(id)) {
        const key = payload.ids[id]
        if (state.entityIds.indexOf(key) < 0) {
          state.entityIds.push(key)
        }
      }
    }
  },
  [types.TASK_ARTICLE_PUBLISH_STATUS_REMOVE_TASK_ARTICLE_PUBLISH_STATUS](
    state: TaskArticlePublishStatusState,
    payload: RemovePayload,
  ) {
    vue.delete(state.entities, payload.id)
    vue.delete(state.entityIds, state.entityIds.indexOf(payload.id))
  },
}
