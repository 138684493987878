import { ActionContext } from 'vuex'
import collaborateNextLib from '@technology/collaborate-next-js'

import types from '~/store/all-mutation-types'

import { AppConfigEntity } from '~/apollo/types'
import { AppState } from '~/store/interfaces/state'
import { SetSnackbarPayload } from '~/store/interfaces/mutationPayload'
import apolloClient from '~/apollo'
import getAppConfig from '~/apollo/queries/getAppConfig.gql'

const actions = {
  async fetchDashboardAppConfig(context: ActionContext<AppState, any>) {
    const { data }: any = await apolloClient.query({
      query: getAppConfig,
    })
    const entity: AppConfigEntity = data.getAppConfig
    context.commit(types.APP_SET_DASHBOARD_APP_CONFIG, {
      entity,
    })
    return entity
  },
  async fetchCollaborateAppConfig(context: ActionContext<AppState, any>) {
    const result = await collaborateNextLib.getInstance().appConfig.get()
    context.commit(types.APP_SET_COLLABORATE_APP_CONFIG, {
      entity: result,
    })
    return result
  },
  setSnackPayload(context: ActionContext<AppState, any>, payload: SetSnackbarPayload | undefined) {
    context.commit(types.APP_SET_SNACKBAR, payload)
  },
  setIsBackgroundLoading(context: ActionContext<AppState, any>, payload: boolean) {
    context.commit(types.APP_SET_IS_BACKGROUND_LOADING, payload)
  },
  setIsMobile(context: ActionContext<AppState, any>, payload: boolean) {
    context.commit(types.APP_SET_IS_MOBILE, payload)
  },
  setIsOnDrag(context: ActionContext<AppState, any>, payload: boolean) {
    context.commit(types.APP_SET_IS_ON_DRAG, payload)
  },
  setValidGroupIds(context: ActionContext<AppState, any>, payload: string[]) {
    context.commit(types.APP_SET_VALID_GROUP_IDS, payload)
  },
}
export default actions
export type AppAction = typeof actions
