// External Plugin
import { ActionContext } from 'vuex'
import { GetListParams } from '@technology/collaborate-next-js/dist/types/modules/board/params'
import { normalize } from 'normalizr'
import collaborateLib from '@technology/collaborate-next-js'

import { board } from '~/schema'
import types from '~/store/all-mutation-types'

// Interface
import { BoardState } from '~/store/interfaces/state'

const actions = {
  async fetchBoards(context: ActionContext<BoardState, any>, payload: GetListParams) {
    let result
    try {
      result = await collaborateLib.getInstance().board.getList({
        groupId: payload.groupId,
      })
    } catch (e) {
      throw e
    }
    const normalizedData = normalize(result.data, [board])

    context.commit(types.BOARD_SET_BOARDS, {
      entities: normalizedData.entities.boards,
      ids: normalizedData.result,
    })
    return result
  },
}
export default actions
export type BoardAction = typeof actions
