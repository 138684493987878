import { AccountsVue } from '@technology/accounts-vue-sdk'
import Vue from 'vue'

import { Store } from 'vuex'
import config from '~/config'

export default (store: Store<any>) => {
  Vue.use(AccountsVue, {
    store,
    accountApiRootUrl: config.accountApiRootUrl,
  })
}
