import vue from 'vue'

// interface
import { BoardModel } from '@technology/collaborate-next-js/dist/types/models'
import { BoardState } from '~/store/interfaces/state'

import * as types from './mutation-types'

export default {
  [types.BOARD_SET_BOARDS](state: BoardState, payload: NormalizedPayload<BoardModel>) {
    for (const entity in payload.entities) {
      if (payload.entities.hasOwnProperty(entity)) {
        const oldObj = state.entities[entity] || {}
        const newObj = { ...oldObj, ...payload.entities[entity] }
        vue.set(state.entities, entity, newObj)
      }
    }
    for (const id in payload.ids) {
      if (payload.ids.hasOwnProperty(id)) {
        const key = payload.ids[id]
        if (state.entityIds.indexOf(key) < 0) {
          state.entityIds.push(key)
        }
      }
    }
  },
}
