// Interface
import { TaskArticlePublishStatusModel } from '@technology/collaborate-next-js/dist/types/models'
import { TaskArticlePublishStatusState } from '~/store/interfaces/state'

const getters = {
  getTaskArticlePublishStatusesByArticleId(state: TaskArticlePublishStatusState) {
    return (articleId: string) => {
      return state.entityIds
        .map(id => state.entities[id])
        .filter(entity => entity.articleId === articleId)
    }
  },
  getTaskArticlePublishStatusesByArticleIds(state: TaskArticlePublishStatusState) {
    return (articleIds: string[]) => {
      return state.entityIds
        .map(id => state.entities[id])
        .filter(entity => articleIds.indexOf(entity.articleId) >= 0)
    }
  },
  getTaskArticlePublishStatusesByTaskId(state: TaskArticlePublishStatusState) {
    return (taskId: string) => {
      return state.entityIds
        .map(id => state.entities[id])
        .filter(entity => entity.taskId === taskId)
    }
  },
  getTaskArticlePublishStatusesByTaskIds(state: TaskArticlePublishStatusState) {
    return (taskIds: string[]) => {
      return state.entityIds
        .map(id => state.entities[id])
        .filter(entity => taskIds.indexOf(entity.taskId) >= 0)
    }
  },
  getTaskArticlePublishStatusesMapByTaskIds(state: TaskArticlePublishStatusState) {
    return (taskIds: string[]) => {
      return state.entityIds
        .map(id => state.entities[id])
        .filter(entity => taskIds.indexOf(entity.taskId) >= 0)
        .reduce((pubMap: KeyMap<TaskArticlePublishStatusModel[]>, pubStatus) => {
          if (!pubMap[pubStatus.taskId]) pubMap[pubStatus.taskId] = []
          pubMap[pubStatus.taskId].push(pubStatus)
          return pubMap
        }, {}) as KeyMap<TaskArticlePublishStatusModel[]>
    }
  },
}

export default getters
export type TaskArticlePublishStatusGetter = typeof getters
