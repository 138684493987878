// Interface
import { TaskState } from '~/store/interfaces/state'

import util from '~/util'

const getters = {
  getTasks(state: TaskState) {
    return state.entityIds.map(id => state.entities[id])
  },
  getParentTaskById(state: TaskState) {
    return (taskId: string) => {
      const task = state.entities[taskId]
      if (task.parentTaskId) return state.entities[task.parentTaskId]
      return task
    }
  },
  getTaskById(state: TaskState) {
    return (taskId: string) => state.entities[taskId]
  },
  getTasksByIds(state: TaskState) {
    return (taskIds: string[]) => taskIds.map(taskId => state.entities[taskId])
  },
  getParentTaskIdsByTaskIds(state: TaskState) {
    return (taskIds: string[]) =>
      util.distinct(taskIds.map(taskId => state.entities[taskId]?.parentTaskId ?? taskId))
  },
  getParentTaskIdMapByTaskIds(state: TaskState) {
    return (taskIds: string[]) =>
      taskIds
        .map(taskId => state.entities[taskId])
        .reduce((taskMap: KeyMap<string>, task) => {
          taskMap[task.id] = task.parentTaskId ?? task.id
          return taskMap
        }, {}) as KeyMap<string>
  },

  /**
   * Get child and parent task by id
   *
   * As type can't references itself, getters will be set to any
   * Ref: Type alias 'TaskGetter' circularly references itself.
   * @param state
   * @param getters
   */
  getAllRelatedTasksById: (state: TaskState, taskGetter: any) => {
    return (taskId: string) => {
      const taskEnt = taskGetter.getTaskById(taskId)
      let relatedTasks = state.entityIds
        .filter(id => state.entities[id].parentTaskId === taskId)
        .map(id => state.entities[id])
      if (taskEnt.parentTaskId) {
        const parentTask = taskGetter.getTaskById(taskEnt.parentTaskId)
        parentTask && (relatedTasks = [...relatedTasks, parentTask])
      }
      return relatedTasks
    }
  },
}

export default getters
export type TaskGetter = typeof getters
