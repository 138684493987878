// interface
import { AppState } from '~/store/interfaces/state'
import {
  SetCollaborateAppConfigPayload,
  SetDashboardAppConfigPayload,
  SetSnackbarPayload,
} from '~/store/interfaces/mutationPayload'

import * as types from './mutation-types'

/*
  Need to use export default({}) rather than export default {}
  ref links: https://github.com/TypeStrong/ts-loader/issues/588
*/
export default {
  [types.APP_SET_COLLABORATE_APP_CONFIG](state: AppState, payload: SetCollaborateAppConfigPayload) {
    const oldObj = state.entity || {}
    const newObj = {
      ...oldObj,
      ...{
        collaborate: payload.entity,
      },
    }
    state.entity = newObj
  },
  [types.APP_SET_DASHBOARD_APP_CONFIG](state: AppState, payload: SetDashboardAppConfigPayload) {
    const oldObj = state.entity || {}
    const newObj = {
      ...oldObj,
      ...{
        dashboard: payload.entity,
      },
    }
    state.entity = newObj
  },
  [types.APP_SET_IS_MOBILE](state: AppState, payload: boolean) {
    state.isMobile = payload
  },
  [types.APP_SET_SNACKBAR](state: AppState, payload: SetSnackbarPayload) {
    state.snackbar = payload
  },
  [types.APP_SET_IS_BACKGROUND_LOADING](state: AppState, payload: boolean) {
    state.isBackgroundLoading = payload
  },
  [types.APP_SET_IS_ON_DRAG](state: AppState, payload: boolean) {
    state.isOnDrag = payload
  },
  [types.APP_SET_VALID_GROUP_IDS](state: AppState, payload: string[]) {
    state.validGroupIds = payload
  },
}
