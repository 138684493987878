import { parse } from 'date-fns'

const util = {
  classToObject<T>(ctor: new (...args: any[]) => T) {
    const objResult: {
      [key: string]: () => any
    } = {}
    Object.getOwnPropertyNames(ctor.prototype).forEach(k => {
      const protoVal: () => any = ctor.prototype[k]
      if (typeof protoVal === 'function' && k !== 'constructor') {
        objResult[k] = (...args: any[]) => {
          return protoVal.call(args)
        }
      }
    })
    return objResult
  },
  checkEmpty(value: any) {
    return (
      value === undefined ||
      value === null ||
      value === '' ||
      value === '0' ||
      value === 0 ||
      value === 0.0 ||
      value === false ||
      (typeof value === 'object' && Object.keys(value).length === 0)
    )
  },
  checkNum(value: any) {
    return !isNaN(parseFloat(value))
  },
  clone(source: any) {
    return { ...source }
  },
  debounce(timer: NodeJS.Timer, func: () => any, time: number = 500) {
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(func, time)
    return timer
  },
  distinct<T>(sourceArray: T[]) {
    return [...new Set(sourceArray)]
  },
  format(fmt: string, ...args: any[]) {
    return fmt.split('%%').reduce((aggregate, chunk, i) => aggregate + chunk + (args[i] || ''), '')
  },
  filterEmpty<T>(sourceArray: T[]) {
    return sourceArray.filter(item => !util.checkEmpty(item))
  },
  deepClone(source: any) {
    return JSON.parse(JSON.stringify(source))
  },
  flatten<T>(list: T[][]): T[] {
    return list.reduce((a, b) => a.concat(Array.isArray(b) ? this.flatten(b) : b), [])
  },
  isEmptyObject(obj: any) {
    return Object.keys(obj).length === 0 && obj.constructor === Object
  },
  isObject(x: any) {
    return x != null && typeof x === 'object'
  },
  isFunction(x: any) {
    return x != null && typeof x === 'function'
  },
  toKeyArray(value: any) {
    return Array.isArray(value) ? value : Object.keys(value)
  },
  replaceAllString(searchStr: string, replaceStr: string, subject: string) {
    return subject.split(searchStr).join(replaceStr)
  },
  determineBrightness(color: string): number {
    if (color) {
      color = util.replaceAllString(' ', '', color.toLocaleLowerCase())

      if (color.startsWith('rgb')) {
        const [, red, green, blue] = color.match(/rgb\((\d+),(\d+),(\d+)\)/i) || ['', '', '', '']
        return Math.round(
          parseInt(red, 10) * 0.2126 + parseInt(green, 10) * 0.7152 + parseInt(blue, 10) * 0.0722,
        )
      }

      if (color.startsWith('#')) {
        // e.g. #fff
        if (color.length === 4) {
          const [, red, green, blue] = color.match(/#([0-9a-f])([0-9a-f])([0-9a-f])/i) || [
            '',
            '',
            '',
            '',
          ]
          return Math.round(
            parseInt(red + red, 16) * 0.2126 +
              parseInt(green + green, 16) * 0.7152 +
              parseInt(blue + blue, 16) * 0.0722,
          )
        }

        // e.g. #ffffff
        if (color.length === 7) {
          const [, red, green, blue] = color.match(/#([0-9a-f]{2})([0-9a-f]{2})([0-9a-f]{2})/i) || [
            '',
            '',
            '',
            '',
          ]
          return Math.round(
            parseInt(red, 16) * 0.2126 + parseInt(green, 16) * 0.7152 + parseInt(blue, 16) * 0.0722,
          )
        }
      }
    }
    return 0
  },
  objectValues<T>(obj: KeyMap<T>): T[] {
    return Object.keys(obj).map(key => obj[key])
  },
  wrapSingleFetch(func: any): any {
    let globalNonce: Object

    return async function (...args: any[]) {
      const localNonce = (globalNonce = new Object())
      const iterator = func(...args)

      let funcReturn

      for (;;) {
        const result: IteratorResult<Promise<any>> = iterator.next(funcReturn)

        if (result.done) {
          return
        }
        funcReturn = await result.value

        if (localNonce !== globalNonce) {
          return
        }
      }
    }
  },
  sortString(a: string, b: string): number {
    const strA = a.toUpperCase()
    const strB = b.toUpperCase()
    if (strA < strB) {
      return -1
    }
    if (strA > strB) {
      return 1
    }
    return 0
  },
  sortDate(a: Date | string, b: Date | string): number {
    const dateA = a instanceof Date ? a : parse(a)
    const dateB = b instanceof Date ? b : parse(b)
    return dateA.getTime() - dateB.getTime()
  },
}

export default util
