import accountsLib from '@technology/accounts-js'
import collaborateNextLib from '@technology/collaborate-next-js'
import rosterLib from '@technology/roster-js'

import './googleAnalytics'
import './idleVue'
import './newsroomPlugin'
import './vueObserveVisibility'
import './vuelidate'
import { eventSourceHandler } from '~/helpers'
import config from '~/config'
import store from '~/store'

import AccountsVue from './accountsVueSdk'
import StoreHelper from './storeHelper'
import Vuetify from './vuetify'

accountsLib.init({
  accessToken: '',
  rootUrl: config.accountApiRootUrl,
})
collaborateNextLib.init({
  baseURL: config.collaborateNextApiRootUrl,
})
rosterLib.setInstance({}, config.rosterApiRootUrl)

eventSourceHandler.setup()

AccountsVue(store)

export { StoreHelper, Vuetify }
