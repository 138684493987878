// Interface
import { TaskArticleState } from '~/store/interfaces/state'

const getters = {
  getTaskArticleById(state: TaskArticleState) {
    return (articleId: string) => state.entities[articleId]
  },
  getTaskArticlesByTaskId(state: TaskArticleState) {
    return (taskId: string) => {
      return state.entityIds
        .map(id => state.entities[id])
        .filter(entity => entity.taskId === taskId)
    }
  },
  getTaskArticlesByTaskIds(state: TaskArticleState) {
    return (taskIds: string[]) => {
      return state.entityIds
        .map(id => state.entities[id])
        .filter(entity => taskIds.indexOf(entity.taskId) >= 0)
    }
  },
}

export default getters
export type TaskComposeArticleGetter = typeof getters
