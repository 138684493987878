// Interface
import { CategoryState } from '~/store/interfaces/state/categoryState'

const getters = {
  getCategories(state: CategoryState) {
    return state.entityIds.map(id => state.entities[id])
  },

  getCategoryById(state: CategoryState) {
    return (categoryId: string) => {
      return state.entities[categoryId]
    }
  },

  getCategoriesByIds(state: CategoryState) {
    return (categoryIds: string[]) => {
      return categoryIds.map(id => state.entities[id])
    }
  },
}

export default getters
export type CategoryGetter = typeof getters
