// External Plugin
import { ActionContext } from 'vuex'
import { normalize } from 'normalizr'

// Internal Plugin
import collaborateNextLib from '@technology/collaborate-next-js'

// In app
import types from '~/store/all-mutation-types'

// Interface
import { GroupConfigState } from '~/store/interfaces/state'
import { groupConfig } from '~/schema'

const actions = {
  async fetchGroupConfigs(context: ActionContext<GroupConfigState, any>) {
    const result = await collaborateNextLib.getInstance().groupConfig.getAll()
    const normalizedData = normalize(result.data, [groupConfig])
    // result will be string only
    // cast it to string array
    context.commit(types.GROUP_CONFIG_SET_GROUP_CONFIG, {
      entities: normalizedData.entities.groupConfigs,
      ids: normalizedData.result,
    })
    return result
  },
}

export default actions
export type GroupConfigAction = typeof actions
