// Interface
import { BoardState } from '~/store/interfaces/state'

const getters = {
  getBoardById(state: BoardState) {
    return (boardId: string) => {
      return state.entities[boardId]
    }
  },
  getByGroupId(state: BoardState) {
    return (groupId: string) => {
      return state.entityIds.map(id => state.entities[id]).filter(b => b.groupId === groupId)
    }
  },
  getGroupName(state: BoardState, _getters: any, _rootState: any, rootGetters: any) {
    return (id: string) => {
      return rootGetters['accounts/group/getById']?.(state.entities[id]?.groupId)?.name as string
    }
  },
  getBoards(state: BoardState) {
    return state.entityIds.map(id => state.entities[id])
  },
}

export default getters
export type BoardGetter = typeof getters
