import { GroupConfigState } from '~/store/interfaces/state'

const getters = {
  getGroupConfigByGroupId(state: GroupConfigState) {
    return (groupId: string) =>
      state.entityIds
        .map(id => state.entities[id])
        .filter(entity => entity.groupId === groupId)
        .reduce(
          (mapping, config) => ({
            ...mapping,
            [config.key]: config.value,
          }),
          {},
        )
  },
  getGroupConfigByGroupIdAndKey(state: GroupConfigState) {
    return (groupId: string, key: string) => {
      const config = state.entityIds
        .map(id => state.entities[id])
        .find(entity => entity.groupId === groupId && entity.key === key)
      return config ? config.value : undefined
    }
  },
}

export default getters
export type GroupConfigGetter = typeof getters
