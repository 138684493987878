// Interface
import { AppState } from '~/store/interfaces/state'

const getters = {
  getCollaborateAppConfig(state: AppState) {
    return state.entity ? state.entity.collaborate : undefined
  },
  getIsMobile(state: AppState) {
    return state.isMobile
  },
  getIsBackgroundLoading(state: AppState) {
    return state.isBackgroundLoading
  },
  getSnackbar(state: AppState) {
    return state.snackbar
  },
  getValidGroupIds(state: AppState) {
    return state.validGroupIds
  },
}
export default getters
export type AppGetter = typeof getters
