import { ActionContext } from 'vuex'
import { SearchWithNestedParams } from '@technology/collaborate-next-js/dist/types/modules/task-search/params'
import { normalize } from 'normalizr'
import collaborateNextLib from '@technology/collaborate-next-js'

import types from '~/store/all-mutation-types'

import { board, task } from '~/schema'

const actions = {
  async fetchTaskAssignment(context: ActionContext<any, any>, payload: SearchWithNestedParams) {
    const result = await collaborateNextLib.getInstance().taskSearch.searchWithNested({
      ...payload,
    })
    const normalizedData = normalize(result.data, [task])
    // result will be string only
    // cast it to string array
    context.commit(types.TASK_SET_TASKS, {
      entities: normalizedData.entities.tasks,
      ids: normalizedData.result,
    })

    if (result.nested.relatedTasks?.length) {
      const normalizedDataRelatedTasks = normalize(result.nested.relatedTasks, [task])
      context.commit(types.TASK_SET_TASKS, {
        entities: normalizedDataRelatedTasks.entities.tasks,
        ids: normalizedDataRelatedTasks.result,
      })
    }

    if (result.nested.boards?.length) {
      const normalizedData = normalize(result.nested.boards, [board])
      context.commit(types.BOARD_SET_BOARDS, {
        entities: normalizedData.entities.boards,
        ids: normalizedData.result,
      })
    }
    return result
  },
}

export default actions
export type TaskAssignmentAction = typeof actions
