// Interface
import { NewPublishState } from '~/store/interfaces/state'

const getters = {
  getNewsPublishes(state: NewPublishState) {
    return state.entityIds.map(id => state.entities[id])
  },
  getNewsPublishByTaskId(state: NewPublishState) {
    return (taskId: string) => {
      return state.entityIds
        .map(id => state.entities[id])
        .find(entity => entity.parentTaskId === taskId)
    }
  },
  getNewsPublishesByTaskId(state: NewPublishState) {
    return (taskId: string) => {
      return state.entityIds
        .map(id => state.entities[id])
        .filter(entity => entity.parentTaskId === taskId)
    }
  },
}

export default getters
export type NewsPublishGetter = typeof getters
