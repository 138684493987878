import * as appMutation from './modules/app/mutation-types'
import * as articlePublishStatusMutation from './modules/taskArticlePublishStatus/mutation-types'
import * as boardMutation from './modules/board/mutation-types'
import * as categoryMutation from './modules/category/mutation-types'
import * as groupConfigMutation from './modules/groupConfig/mutation-types'
import * as newsPublishMutation from './modules/newsPublish/mutation-types'
import * as taskArticleStatusMutation from './modules/taskArticleStatus/mutation-types'
import * as taskAssetMutation from './modules/taskAsset/mutation-types'
import * as taskComposeArticleMutation from './modules/taskArticle/mutation-types'
import * as taskMutation from './modules/task/mutation-types'
import * as widgetConfigMutation from './modules/widgetConfig/mutation-types'

export default {
  ...appMutation,
  ...articlePublishStatusMutation,
  ...boardMutation,
  ...categoryMutation,
  ...groupConfigMutation,
  ...newsPublishMutation,
  ...taskArticleStatusMutation,
  ...taskAssetMutation,
  ...taskComposeArticleMutation,
  ...taskMutation,
  ...widgetConfigMutation,
}
