import { ActionContext } from 'vuex'
import { SearchWithNestedParams } from '@technology/collaborate-next-js/dist/types/modules/task-search/params'
import { normalize } from 'normalizr'
import collaborateNextLib from '@technology/collaborate-next-js'

import {
  board,
  category,
  newsPublish,
  task,
  taskArticle,
  taskArticlePublishStatus,
  taskArticleStatus,
  taskAsset,
} from '~/schema'
import types from '~/store/all-mutation-types'

// Interface

const actions = {
  async fetchTopicManagement(context: ActionContext<any, any>, payload: SearchWithNestedParams) {
    const result = await collaborateNextLib.getInstance().taskSearch.searchWithNested({
      ...payload,
    })
    const normalizedData = normalize(result.data, [task])
    context.commit(types.TASK_SET_TASKS, {
      entities: normalizedData.entities.tasks,
      ids: normalizedData.result,
    })
    // Handle for nested
    if (result.nested?.relatedTasks?.length > 0) {
      const normalizedData = normalize(result.nested.relatedTasks, [task])
      context.commit(types.TASK_SET_TASKS, {
        entities: normalizedData.entities.tasks,
        ids: normalizedData.result,
      })
    }

    if (result.nested?.taskArticles?.length > 0) {
      const normalizedData = normalize(result.nested.taskArticles, [taskArticle])
      context.commit(types.TASK_ARTICLE_SET_TASK_ARTICLE, {
        entities: normalizedData.entities.taskArticles,
        ids: normalizedData.result,
      })
    }

    if (result.nested?.categories?.length > 0) {
      const normalizedData = normalize(result.nested.categories, [category])
      context.commit(types.CATEGORY_SET_CATEGORIES, {
        entities: normalizedData.entities.categories,
        ids: normalizedData.result,
      })
    }

    if (result.nested?.newsPublishes?.length > 0) {
      const normalizedData = normalize(result.nested.newsPublishes, [newsPublish])
      context.commit(types.NEWS_PUBLISH_SET_NEWS_PUBLISH, {
        entities: normalizedData.entities.newsPublishes,
        ids: normalizedData.result,
      })
    }

    if (result.nested?.taskAssets?.length > 0) {
      const normalizedData = normalize(result.nested.taskAssets, [taskAsset])
      context.commit(types.TASK_ASSET_SET_TASK_ASSET, {
        entities: normalizedData.entities.taskAssets,
        ids: normalizedData.result,
      })
    }

    if (result.nested?.taskArticlePublishStatuses?.length > 0) {
      const normalizedData = normalize(result.nested.taskArticlePublishStatuses, [
        taskArticlePublishStatus,
      ])
      context.commit(types.TASK_ARTICLE_PUBLISH_STATUS_SET_TASK_ARTICLE_PUBLISH_STATUS, {
        entities: normalizedData.entities.taskArticlePublishStatuses,
        ids: normalizedData.result,
      })
    }

    if (result.nested.taskArticleStatuses?.length > 0) {
      const normalizedData = normalize(result.nested.taskArticleStatuses, [taskArticleStatus])

      context.commit(types.TASK_ARTICLE_STATUS_SET_TASK_ARTICLE_STATUS, {
        entities: normalizedData.entities.taskArticleStatuses,
        ids: normalizedData.result,
      })
    }

    if (result.nested.boards?.length > 0) {
      const normalizedData = normalize(result.nested.boards, [board])
      context.commit(types.BOARD_SET_BOARDS, {
        entities: normalizedData.entities.boards,
        ids: normalizedData.result,
      })
    }
    return result
  },
}

export default actions
export type TopicManagementAction = typeof actions
