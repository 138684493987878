import Vue from 'vue'

Vue.mixin({
  methods: {
    sendGAAction(
      hitType: string,
      eventCategory: string = '',
      eventAction: string = '',
      eventLabel: string = '',
    ) {
      const evt = new CustomEvent('gaaction', {
        detail: [hitType, eventCategory, eventAction, eventLabel],
      })
      window.dispatchEvent(evt)
    },
  },
})
