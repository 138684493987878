import { type } from '~/util/type'

export const APP_SET_COLLABORATE_APP_CONFIG = type('APP_SET_COLLABORATE_APP_CONFIG')
export const APP_SET_DASHBOARD_APP_CONFIG = type('APP_SET_DASHBOARD_APP_CONFIG')

export const APP_SET_IS_MOBILE = type('APP_SET_IS_MOBILE')
export const APP_SET_IS_ON_DRAG = type('APP_SET_IS_ON_DRAG')
export const APP_SET_SNACKBAR = type('APP_SET_SNACKBAR')
export const APP_SET_IS_BACKGROUND_LOADING = type('APP_SET_IS_BACKGROUND_LOADING')
export const APP_SET_VALID_GROUP_IDS = type('APP_SET_VALID_GROUP_IDS')
