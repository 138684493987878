// Interface
import { TaskAssetState } from '~/store/interfaces/state'

const getters = {
  getTaskAssets(state: TaskAssetState) {
    return state.entityIds.map(id => state.entities[id])
  },
  getTaskAssetById(state: TaskAssetState) {
    return (assetId: string) => state.entities[assetId]
  },
  getTaskAssetsByTaskId(state: TaskAssetState) {
    return (taskId: string) => {
      return state.entityIds
        .map(id => state.entities[id])
        .filter(entity => entity.taskId === taskId)
    }
  },
}

export default getters
export type TaskAssetGetter = typeof getters
