import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

import Dashboard from '~/views/Dashboard.vue'
import PageNotFound from '~/views/PageNotFound.vue'
import Root from '~/views/Root.vue'

Vue.use(VueRouter)

const Routes: RouteConfig[] = [
  {
    path: '/',
    component: Root,
    children: [
      {
        component: Dashboard,
        path: '',
        name: 'Dashboard',
      },
    ],
  },
  { path: '/error', component: PageNotFound },
  { path: '*', redirect: '/error' },
]
export default new VueRouter({
  mode: 'history',
  routes: Routes,
})
