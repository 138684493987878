import vue from 'vue'

// interface
import {
  RemoveWidgetConfigPayload,
  SetWidgetConfigsPayload,
} from '~/store/interfaces/mutationPayload'
import { WidgetConfigState } from '~/store/interfaces/state'

import * as types from './mutation-types'

/*
  Need to use export default({}) rather than export default {}
  ref links: https://github.com/TypeStrong/ts-loader/issues/588
*/
export default {
  [types.WIDGET_CONFIG_SET_WIDGET_CONFIG](
    state: WidgetConfigState,
    payload: SetWidgetConfigsPayload,
  ) {
    for (const entity in payload.entities) {
      if (payload.entities.hasOwnProperty(entity)) {
        const oldObj = state.entities[entity] || {}
        const newObj = { ...oldObj, ...payload.entities[entity] }
        vue.set(state.entities, entity, newObj)
      }
    }
    for (const id in payload.ids) {
      if (payload.ids.hasOwnProperty(id)) {
        const key = payload.ids[id]
        if (state.entityIds.indexOf(key) < 0) {
          state.entityIds.push(key)
        }
      }
    }
  },
  [types.WIDGET_CONFIG_REMOVE_WIDGET_CONFIG](
    state: WidgetConfigState,
    payload: RemoveWidgetConfigPayload,
  ) {
    vue.delete(state.entities, payload.id)
    vue.delete(state.entityIds, state.entityIds.indexOf(payload.id))
  },
}
