// Interface
import { WidgetConfigState } from '~/store/interfaces/state'

const getters = {
  getWidgetConfigs(state: WidgetConfigState) {
    return state.entityIds.map(id => state.entities[id])
  },

  getWidgetConfigById(state: WidgetConfigState) {
    return (taskId: string) => state.entities[taskId]
  },

  getWidgetConfigsByType(state: WidgetConfigState) {
    return (type: string) =>
      state.entityIds
        .map(id => state.entities[id])
        .filter(widgetConfig => widgetConfig.type === type)
  },
}

export default getters
export type WidgetConfigGetter = typeof getters
