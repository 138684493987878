// External Plugin
import { ActionContext } from 'vuex'
import { normalize } from 'normalizr'
import types from '~/store/all-mutation-types'

// In app

import { widgetConfig } from '~/schema'

// Gql
import createWidgetConfig from '~/apollo/mutations/createWidgetConfig.gql'
import deleteWidgetConfig from '~/apollo/mutations/deleteWidgetConfig.gql'
import getWidgetConfigs from '~/apollo/queries/getWidgetConfigs.gql'
import updateWidgetConfig from '~/apollo/mutations/updateWidgetConfig.gql'
import updateWidgetConfigPosition from '~/apollo/mutations/updateWidgetConfigPosition.gql'

// Interface
import {
  CreateWidgetConfigBody,
  DeleteWidgetConfigBody,
  RemoveWidgetConfigFromPayload,
  SaveWidgetConfigsFromPayload,
  UpdateWidgetConfigBody,
  UpdateWidgetConfigPositionBody,
} from '~/store/interfaces/actionBody'
import { WidgetConfigEntity } from '~/apollo/types'
import { WidgetConfigState } from '~/store/interfaces/state'
import apolloClient from '~/apollo'

const actions = {
  async createWidgetConfig(
    context: ActionContext<WidgetConfigState, any>,
    payload: CreateWidgetConfigBody,
  ) {
    try {
      const { data }: any = await apolloClient.mutate({
        mutation: createWidgetConfig,
        variables: {
          createWidgetConfigArrayInput: payload.body,
        },
      })
      const entities: WidgetConfigEntity[] = data.createWidgetConfig
      const normalizedData = normalize(entities, [widgetConfig])
      context.commit(types.WIDGET_CONFIG_SET_WIDGET_CONFIG, {
        entities: normalizedData.entities.widgetConfigs,
        ids: normalizedData.result,
      })
      return entities
    } catch (e) {
      throw e
    }
  },
  async deleteWidgetConfig(
    context: ActionContext<WidgetConfigState, any>,
    payload: DeleteWidgetConfigBody,
  ) {
    try {
      const { data }: any = await apolloClient.mutate({
        mutation: deleteWidgetConfig,
        variables: {
          id: payload.widgetConfigId,
        },
      })
      if (data.deleteWidgetConfig) {
        context.commit(types.WIDGET_CONFIG_REMOVE_WIDGET_CONFIG, {
          id: payload.widgetConfigId,
        })
      }
    } catch (e) {
      throw e
    }
  },
  updateWidgetConfig(
    context: ActionContext<WidgetConfigState, any>,
    payload: UpdateWidgetConfigBody,
  ) {
    try {
      // No need to wait for the server response
      apolloClient.mutate({
        mutation: updateWidgetConfig,
        variables: {
          id: payload.widgetConfigId,
          updateWidgetConfigInput: {
            ...payload.body,
          },
        },
      })
      context.commit(types.WIDGET_CONFIG_SET_WIDGET_CONFIG, {
        entities: {
          [payload.widgetConfigId]: { ...payload.body },
        },
        ids: [payload.widgetConfigId],
      })
    } catch (e) {
      throw e
    }
  },
  async updateWidgetConfigPosition(
    context: ActionContext<WidgetConfigState, any>,
    payload: UpdateWidgetConfigPositionBody,
  ) {
    try {
      const { data }: any = await apolloClient.mutate({
        mutation: updateWidgetConfigPosition,
        variables: {
          id: payload.widgetConfigId,
          updateWidgetConfigPositionInput: {
            ...payload.body,
          },
        },
      })
      const entity: WidgetConfigEntity = data.updateWidgetConfigPosition
      const normalizedData = normalize(entity, widgetConfig)
      context.commit(types.WIDGET_CONFIG_SET_WIDGET_CONFIG, {
        entities: normalizedData.entities.widgetConfigs,
        ids: [normalizedData.result],
      })
    } catch (e) {
      throw e
    }
  },
  async fetchWidgetConfig(context: ActionContext<WidgetConfigState, any>) {
    try {
      const { data }: any = await apolloClient.query({
        query: getWidgetConfigs,
      })
      const entities: WidgetConfigEntity[] = data.getWidgetConfigs
      context.dispatch('saveWidgetConfigsFromPayload', {
        entities,
      })
      return entities
    } catch (e) {
      throw e
    }
  },

  /**
   * supporting the data from socket
   *
   * @param {ActionContext<WidgetConfigState, any>} context
   * @param {SaveWidgetConfigsFromPayload} payload
   * @memberof TaskAction
   */
  async saveWidgetConfigsFromPayload(
    context: ActionContext<WidgetConfigState, any>,
    payload: SaveWidgetConfigsFromPayload,
  ) {
    try {
      const normalizedData = normalize(payload.entities, [widgetConfig])
      context.commit(types.WIDGET_CONFIG_SET_WIDGET_CONFIG, {
        entities: normalizedData.entities.widgetConfigs,
        ids: normalizedData.result,
      })
    } catch (err) {
      throw err
    }
  },

  /**
   * supporting the data from socket
   *
   * @param {ActionContext<WidgetConfigState, any>} context
   * @param {RemoveWidgetConfigFromPayload} payload
   * @memberof TaskAction
   */
  async removeWidgetConfigsFromPayload(
    context: ActionContext<WidgetConfigState, any>,
    payload: RemoveWidgetConfigFromPayload,
  ) {
    try {
      context.commit(types.WIDGET_CONFIG_REMOVE_WIDGET_CONFIG, {
        id: payload.entity.id,
      })
    } catch (err) {
      throw err
    }
  },
}

export default actions
export type WidgetConfigAction = typeof actions
