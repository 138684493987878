// Interface
import { TaskArticleStatusState } from '~/store/interfaces/state'

const getters = {
  getTaskArticleStatusesByArticleId(state: TaskArticleStatusState) {
    return (articleId: string) => {
      return state.entityIds
        .map(id => state.entities[id])
        .filter(entity => entity.articleId === articleId)
    }
  },
  getTaskArticleStatusesByArticleIds(state: TaskArticleStatusState) {
    return (articleIds: string[]) => {
      return state.entityIds
        .map(id => state.entities[id])
        .filter(entity => articleIds.indexOf(entity.articleId) >= 0)
    }
  },
}

export default getters
export type TaskArticleStatusGetter = typeof getters
